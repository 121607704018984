import React, { useEffect, useState } from "react";

export default function SelectWithSearch(props) {
  const { categories, category, setCategory } = props;
  const [search, setSearch] = useState("");
  const [filteredCategories, setFilteredCategories] = useState([]);
  
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    var filtered = categories.slice(0, 4);
    if (search !== "" && search !== undefined) {
      filtered = categories.filter((category) =>
        category.label.toLowerCase().includes(search?.toLowerCase())
      );
    }
    setFilteredCategories(filtered);
  }, [search]);

  return (
    <div className="w-full max-w-sm mx-auto relative">
      <div
        className="w-full px-4 py-2 border rounded-md bg-white text-black dark:text-white cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        {category
          ? categories.find((cat) => cat.value === category)?.label
          : "Select a category"}
      </div>
      {isOpen && (
        <div className="absolute w-full mt-1 border rounded-md bg-white dark:bg-gray-800 dark:border-gray-700 shadow-md">
          <input
            type="text"
            placeholder="Search categories..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className="w-full p-2 border-b dark:bg-gray-800 dark:border-gray-700 dark:text-white"
          />
          <ul>
            {filteredCategories.map((category) => (
              <li
                key={category.value}
                className="p-2 hover:bg-gray-200 dark:hover:bg-white dark:text-white text-black cursor-pointer"
                onClick={() => {
                  setCategory(category.value);
                  setIsOpen(false);
                }}
              >
                {category.label}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}
